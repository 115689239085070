import initAddFormItem from './add-form-item';
import categories from './categories';
import updateCheckboxesMaxAnswers from './checkbox-maxanswers-update';
import dataSubmit from './data-submit';
import initForm from './form';
import initInputs from './inputs/index';
import refreshInputs from './inputs/refresh';
import initLoading from './loading';
import './validation';
// eslint-disable-next-line import/extensions,import/no-unresolved
import initMaterialize from '../../base/form/index';
import initRevealBeforeSubmit from './reveal-before-submit';
import revealSubValidate from './reveal-sub-validate';
import setOnSelect from './set-on-select';
import initUpdateForm from './update-form';
import refresh from './refresh';

export default () => {
  initInputs();
  initForm();
  initAddFormItem();
  initUpdateForm();
  initRevealBeforeSubmit();
  dataSubmit();
  setOnSelect();
  refresh();
  initLoading();
  categories();
  revealSubValidate();
  updateCheckboxesMaxAnswers();
  refresh();
}
