import tinymce from 'tinymce';

// Plugin needs to be constructable, so we cannot have an arrow function.
// eslint-disable-next-line prefer-arrow-callback
tinymce.PluginManager.add('internallinks', function Plugin(editor) {
  const internalLinks = editor.getParam('internal_links');
  if (internalLinks) {
    const {
      label,
      links: { label: linkLabel, links },
      text: { label: textLabel },
    } = internalLinks;
    if (links.length) {
      const listBoxValues = links.map((link) => ({
        text: link.label,
        value: JSON.stringify({ href: link.href, text: link.text }),
      }));
      editor.ui.registry.addButton('links', {
        text: label,
        icon: 'link',
        onAction: () => {
          // Open window
          editor.windowManager.open({
            title: label,
            body: {
              type: 'panel',
              items: [
                {
                  type: 'selectbox',
                  name: 'linkValue',
                  label: linkLabel,
                  items: listBoxValues,
                },
                {
                  type: 'input',
                  name: 'linkText',
                  label: textLabel,
                },
              ],
            },
            buttons: [
              {
                type: 'cancel',
                name: 'cancel',
                text: 'Cancel',
              },
              {
                type: 'submit',
                name: 'submitButton',
                text: 'Ok',
                primary: true,
              },
            ],
            initialData: {
              linkText: links[0].text,
            },
            onChange: (dialogApi, details) => {
              const { linkValue } = dialogApi.getData();
              const { text } = JSON.parse(linkValue);
              if (details.name === 'linkValue') {
                dialogApi.setData({ linkText: text });
              }
            },
            onSubmit: (dialogApi) => {
              const { linkText, linkValue } = dialogApi.getData();
              const { href, text } = JSON.parse(linkValue);
              editor.insertContent(`
                <a href="${href}" target="_blank" rel="nofollow noopener noreferrer">${linkText || text}</a>
              `);
              dialogApi.close();
            },
          });
        },
      });
    }
  }
});
